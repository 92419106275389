var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card-actions', {
    attrs: {
      "no-actions": true,
      "title": "List Barang"
    }
  }, [_vm.cek.length > 0 ? [_c('b-button', {
    staticClass: "mb-1 mr-2",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.modalEditKomisi();
      }
    }
  }, [_vm._v("Update Komisi (" + _vm._s(this.cek.length == 0 ? null : this.cek.length) + ")")]), _c('b-button', {
    staticClass: "mb-1",
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.modalEditPoin();
      }
    }
  }, [_vm._v("Update Poin (" + _vm._s(this.cek.length == 0 ? null : this.cek.length) + ")")])] : [_c('b-button', {
    staticClass: "mb-1 mr-2",
    attrs: {
      "disabled": "",
      "variant": "primary"
    }
  }, [_vm._v("Update Komisi ")]), _c('b-button', {
    staticClass: "mb-1",
    attrs: {
      "disabled": "",
      "variant": "success"
    }
  }, [_vm._v("Update Poin ")])], _c('header', {
    staticClass: "mb-1"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page ")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Sort",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "size": "sm",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function callback($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function callback($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6",
      "sm": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Filter",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput",
      "size": "sm"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    staticClass: "w-75",
    attrs: {
      "id": "filterInput",
      "type": "search",
      "size": "sm",
      "placeholder": "Type to Search"
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "size": "sm",
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-table', {
    attrs: {
      "striped": "",
      "small": "",
      "hover": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "items": _vm.items,
      "fields": _vm.fieldsOwner,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "thead-top",
      fn: function fn(data) {
        return [_c('b-tr', [_c('b-th', {
          staticStyle: {
            "background-color": "transparent"
          },
          attrs: {
            "colspan": "5"
          }
        }), _c('b-th', {
          staticClass: "text-center",
          attrs: {
            "colspan": "2"
          }
        }, [_vm._v("Komisi Cabang")]), _c('b-th', {
          staticStyle: {
            "background-color": "transparent"
          },
          attrs: {
            "colspan": "3"
          }
        })], 1)];
      }
    }, {
      key: "cell(harga_dasar)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm._v(" Rp. " + _vm._s(_vm.formatRupiah(item.harga_dasar)) + " ")];
      }
    }, {
      key: "cell(cek)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "value": item
          },
          model: {
            value: _vm.cek,
            callback: function callback($$v) {
              _vm.cek = $$v;
            },
            expression: "cek"
          }
        })];
      }
    }, {
      key: "cell(no)",
      fn: function fn(_ref3) {
        var index = _ref3.index;
        return [_vm._v(" " + _vm._s(index + 1) + " ")];
      }
    }, {
      key: "cell(komisi_affiliate)",
      fn: function fn(_ref4) {
        var value = _ref4.value;
        return [value ? _c('b', [_vm._v(" " + _vm._s(value.type == "nominal" ? "".concat(_vm.formatRupiah(value.value)) : "".concat(value.value, "%")) + " ")]) : _c('b', [_vm._v("-")])];
      }
    }, {
      key: "cell(komisi_cabang)",
      fn: function fn(_ref5) {
        var value = _ref5.value;
        return [value ? _c('b', [_vm._v(" " + _vm._s(value.type == "nominal" ? "".concat(_vm.formatRupiah(value.value)) : "".concat(value.value, "%")) + " ")]) : _c('b', [_vm._v("-")])];
      }
    }, {
      key: "cell(share_jasa_cabang)",
      fn: function fn(_ref6) {
        var value = _ref6.value;
        return [value ? _c('b', [_vm._v(" " + _vm._s(value.type == "nominal" ? "".concat(_vm.formatRupiah(value.value)) : "".concat(value.value, "%")) + " ")]) : _c('b', [_vm._v("-")])];
      }
    }, {
      key: "cell(komisi_bulanan)",
      fn: function fn(_ref7) {
        var value = _ref7.value;
        return [value ? _c('b', [_vm._v(" " + _vm._s(value.type == "nominal" ? "".concat(_vm.formatRupiah(value.value)) : "".concat(value.value, "%")) + " ")]) : _c('b', [_vm._v("-")])];
      }
    }, {
      key: "cell(poin)",
      fn: function fn(_ref8) {
        var value = _ref8.value;
        return [value ? _c('b', [_vm._v(" " + _vm._s(value.value) + " ")]) : _c('b', [_vm._v("-")])];
      }
    }, {
      key: "cell(action)",
      fn: function fn(_ref9) {
        var item = _ref9.item,
          index = _ref9.index;
        return [_c('section', {
          staticClass: "d-flex justify-content-center"
        }, [_c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "id": "delete-btn-".concat(item.id),
            "size": "sm",
            "variant": "gradient-primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.modalEditKomisiPoin(item);
            }
          }
        }, [_vm._v("Update Komisi Dan Poin ")])], 1)];
      }
    }])
  }), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1), _c('b-modal', {
    attrs: {
      "centered": "",
      "size": "lg",
      "id": "editKomisiPoin",
      "title": "Ubah Komisi Dan Poin",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.simpanKomisiPoin($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('h5', [_vm._v("Komisi Affiliate")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 'nominal',
        text: 'Nominal'
      }, {
        value: 'percentage',
        text: 'Persentase'
      }]
    },
    model: {
      value: _vm.formKomisiPoin.komisi_affiliate.type,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_affiliate, "type", $$v);
      },
      expression: "formKomisiPoin.komisi_affiliate.type"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nilai"
    }
  }, [_vm.formKomisiPoin.komisi_affiliate.type == 'nominal' ? _c('b-input-group', {
    staticClass: "input-group-merge",
    attrs: {
      "prepend": "Rp."
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Nominal Komisi"
    },
    on: {
      "keyup": function keyup($event) {
        return _vm.doFormatHarga('komisi_affiliate');
      }
    },
    model: {
      value: _vm.formKomisiPoin.komisi_affiliate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_affiliate, "value", $$v);
      },
      expression: "formKomisiPoin.komisi_affiliate.value"
    }
  })], 1) : _vm.formKomisiPoin.komisi_affiliate.type == 'percentage' ? _c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "Persentase Komisi"
    },
    model: {
      value: _vm.formKomisiPoin.komisi_affiliate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_affiliate, "value", $$v);
      },
      expression: "formKomisiPoin.komisi_affiliate.value"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_vm._v("%")])], 1) : _c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "placeholder": "Nominal Komisi"
    },
    model: {
      value: _vm.formKomisiPoin.komisi_affiliate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_affiliate, "value", $$v);
      },
      expression: "formKomisiPoin.komisi_affiliate.value"
    }
  })], 1)], 1)], 1)], 1), _c('hr'), _c('h5', [_vm._v("Komisi Cabang")]), _c('center', [_c('u', {
    staticClass: "text-center"
  }, [_vm._v("Utama")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 'nominal',
        text: 'Nominal'
      }, {
        value: 'percentage',
        text: 'Persentase'
      }]
    },
    model: {
      value: _vm.formKomisiPoin.komisi_cabang.type,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_cabang, "type", $$v);
      },
      expression: "formKomisiPoin.komisi_cabang.type"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nilai"
    }
  }, [_vm.formKomisiPoin.komisi_cabang.type == 'nominal' ? _c('b-input-group', {
    staticClass: "input-group-merge",
    attrs: {
      "prepend": "Rp."
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Nominal Komisi"
    },
    on: {
      "keyup": function keyup($event) {
        return _vm.doFormatHarga('komisi_cabang');
      }
    },
    model: {
      value: _vm.formKomisiPoin.komisi_cabang.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_cabang, "value", $$v);
      },
      expression: "formKomisiPoin.komisi_cabang.value"
    }
  })], 1) : _vm.formKomisiPoin.komisi_cabang.type == 'percentage' ? _c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "Persentase Komisi"
    },
    model: {
      value: _vm.formKomisiPoin.komisi_cabang.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_cabang, "value", $$v);
      },
      expression: "formKomisiPoin.komisi_cabang.value"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_vm._v("%")])], 1) : _c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "placeholder": "Nominal Komisi"
    },
    model: {
      value: _vm.formKomisiPoin.komisi_cabang.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_cabang, "value", $$v);
      },
      expression: "formKomisiPoin.komisi_cabang.value"
    }
  })], 1)], 1)], 1)], 1), _c('center', [_c('u', {
    staticClass: "text-center"
  }, [_vm._v("Share")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 'nominal',
        text: 'Nominal'
      }, {
        value: 'percentage',
        text: 'Persentase'
      }]
    },
    model: {
      value: _vm.formKomisiPoin.share_jasa_cabang.type,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.share_jasa_cabang, "type", $$v);
      },
      expression: "formKomisiPoin.share_jasa_cabang.type"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nilai"
    }
  }, [_vm.formKomisiPoin.share_jasa_cabang.type == 'nominal' ? _c('b-input-group', {
    staticClass: "input-group-merge",
    attrs: {
      "prepend": "Rp."
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Nominal Komisi"
    },
    on: {
      "keyup": function keyup($event) {
        return _vm.doFormatHarga('share_jasa_cabang');
      }
    },
    model: {
      value: _vm.formKomisiPoin.share_jasa_cabang.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.share_jasa_cabang, "value", $$v);
      },
      expression: "formKomisiPoin.share_jasa_cabang.value"
    }
  })], 1) : _vm.formKomisiPoin.share_jasa_cabang.type == 'percentage' ? _c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "Persentase Komisi"
    },
    model: {
      value: _vm.formKomisiPoin.share_jasa_cabang.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.share_jasa_cabang, "value", $$v);
      },
      expression: "formKomisiPoin.share_jasa_cabang.value"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_vm._v("%")])], 1) : _c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "placeholder": "Nominal Komisi"
    },
    model: {
      value: _vm.formKomisiPoin.share_jasa_cabang.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.share_jasa_cabang, "value", $$v);
      },
      expression: "formKomisiPoin.share_jasa_cabang.value"
    }
  })], 1)], 1)], 1)], 1), _c('hr'), _c('h5', [_vm._v("Komisi Bulanan")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 'nominal',
        text: 'Nominal'
      }, {
        value: 'percentage',
        text: 'Persentase'
      }]
    },
    model: {
      value: _vm.formKomisiPoin.komisi_bulanan.type,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_bulanan, "type", $$v);
      },
      expression: "formKomisiPoin.komisi_bulanan.type"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nilai"
    }
  }, [_vm.formKomisiPoin.komisi_bulanan.type == 'nominal' ? _c('b-input-group', {
    staticClass: "input-group-merge",
    attrs: {
      "prepend": "Rp."
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Nominal Komisi"
    },
    on: {
      "keyup": function keyup($event) {
        return _vm.doFormatHarga('komisi_bulanan');
      }
    },
    model: {
      value: _vm.formKomisiPoin.komisi_bulanan.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_bulanan, "value", $$v);
      },
      expression: "formKomisiPoin.komisi_bulanan.value"
    }
  })], 1) : _vm.formKomisiPoin.komisi_bulanan.type == 'percentage' ? _c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "Persentase Komisi"
    },
    model: {
      value: _vm.formKomisiPoin.komisi_bulanan.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_bulanan, "value", $$v);
      },
      expression: "formKomisiPoin.komisi_bulanan.value"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_vm._v("%")])], 1) : _c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "placeholder": "Nominal Komisi"
    },
    model: {
      value: _vm.formKomisiPoin.komisi_bulanan.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_bulanan, "value", $$v);
      },
      expression: "formKomisiPoin.komisi_bulanan.value"
    }
  })], 1)], 1)], 1)], 1), _c('hr'), _c('h5', [_vm._v("Poin")]), _c('b-form-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "Poin"
    },
    model: {
      value: _vm.formKomisiPoin.poin.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.poin, "value", $$v);
      },
      expression: "formKomisiPoin.poin.value"
    }
  })], 1)], 1), _c('b-modal', {
    attrs: {
      "centered": "",
      "size": "lg",
      "id": "editKomisi",
      "title": "Ubah Komisi ",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.simpanKomisi($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('h5', [_vm._v("Komisi Affiliate")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 'nominal',
        text: 'Nominal'
      }, {
        value: 'percentage',
        text: 'Persentase'
      }]
    },
    model: {
      value: _vm.formKomisiPoin.komisi_affiliate.type,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_affiliate, "type", $$v);
      },
      expression: "formKomisiPoin.komisi_affiliate.type"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nilai"
    }
  }, [_vm.formKomisiPoin.komisi_affiliate.type == 'nominal' ? _c('b-input-group', {
    staticClass: "input-group-merge",
    attrs: {
      "prepend": "Rp."
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Nominal Komisi"
    },
    on: {
      "keyup": function keyup($event) {
        return _vm.doFormatHarga('komisi_affiliate');
      }
    },
    model: {
      value: _vm.formKomisiPoin.komisi_affiliate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_affiliate, "value", $$v);
      },
      expression: "formKomisiPoin.komisi_affiliate.value"
    }
  })], 1) : _vm.formKomisiPoin.komisi_affiliate.type == 'percentage' ? _c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "Persentase Komisi"
    },
    model: {
      value: _vm.formKomisiPoin.komisi_affiliate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_affiliate, "value", $$v);
      },
      expression: "formKomisiPoin.komisi_affiliate.value"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_vm._v("%")])], 1) : _c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "placeholder": "Nominal Komisi"
    },
    model: {
      value: _vm.formKomisiPoin.komisi_affiliate.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_affiliate, "value", $$v);
      },
      expression: "formKomisiPoin.komisi_affiliate.value"
    }
  })], 1)], 1)], 1)], 1), _c('hr'), _c('h5', [_vm._v("Komisi Cabang")]), _c('center', [_c('u', {
    staticClass: "text-center"
  }, [_vm._v("Utama")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 'nominal',
        text: 'Nominal'
      }, {
        value: 'percentage',
        text: 'Persentase'
      }]
    },
    model: {
      value: _vm.formKomisiPoin.komisi_cabang.type,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_cabang, "type", $$v);
      },
      expression: "formKomisiPoin.komisi_cabang.type"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nilai"
    }
  }, [_vm.formKomisiPoin.komisi_cabang.type == 'nominal' ? _c('b-input-group', {
    staticClass: "input-group-merge",
    attrs: {
      "prepend": "Rp."
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Nominal Komisi"
    },
    on: {
      "keyup": function keyup($event) {
        return _vm.doFormatHarga('komisi_cabang');
      }
    },
    model: {
      value: _vm.formKomisiPoin.komisi_cabang.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_cabang, "value", $$v);
      },
      expression: "formKomisiPoin.komisi_cabang.value"
    }
  })], 1) : _vm.formKomisiPoin.komisi_cabang.type == 'percentage' ? _c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "Persentase Komisi"
    },
    model: {
      value: _vm.formKomisiPoin.komisi_cabang.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_cabang, "value", $$v);
      },
      expression: "formKomisiPoin.komisi_cabang.value"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_vm._v("%")])], 1) : _c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "placeholder": "Nominal Komisi"
    },
    model: {
      value: _vm.formKomisiPoin.komisi_cabang.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_cabang, "value", $$v);
      },
      expression: "formKomisiPoin.komisi_cabang.value"
    }
  })], 1)], 1)], 1)], 1), _c('center', [_c('u', {
    staticClass: "text-center"
  }, [_vm._v("Share")])]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 'nominal',
        text: 'Nominal'
      }, {
        value: 'percentage',
        text: 'Persentase'
      }]
    },
    model: {
      value: _vm.formKomisiPoin.share_jasa_cabang.type,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.share_jasa_cabang, "type", $$v);
      },
      expression: "formKomisiPoin.share_jasa_cabang.type"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nilai"
    }
  }, [_vm.formKomisiPoin.share_jasa_cabang.type == 'nominal' ? _c('b-input-group', {
    staticClass: "input-group-merge",
    attrs: {
      "prepend": "Rp."
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Nominal Komisi"
    },
    on: {
      "keyup": function keyup($event) {
        return _vm.doFormatHarga('share_jasa_cabang');
      }
    },
    model: {
      value: _vm.formKomisiPoin.share_jasa_cabang.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.share_jasa_cabang, "value", $$v);
      },
      expression: "formKomisiPoin.share_jasa_cabang.value"
    }
  })], 1) : _vm.formKomisiPoin.share_jasa_cabang.type == 'percentage' ? _c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "Persentase Komisi"
    },
    model: {
      value: _vm.formKomisiPoin.share_jasa_cabang.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.share_jasa_cabang, "value", $$v);
      },
      expression: "formKomisiPoin.share_jasa_cabang.value"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_vm._v("%")])], 1) : _c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "placeholder": "Nominal Komisi"
    },
    model: {
      value: _vm.formKomisiPoin.share_jasa_cabang.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.share_jasa_cabang, "value", $$v);
      },
      expression: "formKomisiPoin.share_jasa_cabang.value"
    }
  })], 1)], 1)], 1)], 1), _c('hr'), _c('h5', [_vm._v("Komisi Bulanan")]), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Type"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": [{
        value: 'nominal',
        text: 'Nominal'
      }, {
        value: 'percentage',
        text: 'Persentase'
      }]
    },
    model: {
      value: _vm.formKomisiPoin.komisi_bulanan.type,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_bulanan, "type", $$v);
      },
      expression: "formKomisiPoin.komisi_bulanan.type"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nilai"
    }
  }, [_vm.formKomisiPoin.komisi_bulanan.type == 'nominal' ? _c('b-input-group', {
    staticClass: "input-group-merge",
    attrs: {
      "prepend": "Rp."
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Nominal Komisi"
    },
    on: {
      "keyup": function keyup($event) {
        return _vm.doFormatHarga('komisi_bulanan');
      }
    },
    model: {
      value: _vm.formKomisiPoin.komisi_bulanan.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_bulanan, "value", $$v);
      },
      expression: "formKomisiPoin.komisi_bulanan.value"
    }
  })], 1) : _vm.formKomisiPoin.komisi_bulanan.type == 'percentage' ? _c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "placeholder": "Persentase Komisi"
    },
    model: {
      value: _vm.formKomisiPoin.komisi_bulanan.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_bulanan, "value", $$v);
      },
      expression: "formKomisiPoin.komisi_bulanan.value"
    }
  }), _c('b-input-group-append', {
    attrs: {
      "is-text": ""
    }
  }, [_vm._v("%")])], 1) : _c('b-input-group', {
    staticClass: "input-group-merge"
  }, [_c('b-form-input', {
    attrs: {
      "disabled": "",
      "placeholder": "Nominal Komisi"
    },
    model: {
      value: _vm.formKomisiPoin.komisi_bulanan.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.komisi_bulanan, "value", $$v);
      },
      expression: "formKomisiPoin.komisi_bulanan.value"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "centered": "",
      "size": "lg",
      "id": "editPoin",
      "title": "Ubah Poin",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.simpanPoin($event);
            }
          }
        }, [_vm._v("Simpan")])];
      },
      proxy: true
    }])
  }, [_c('h5', [_vm._v("Poin")]), _c('b-form-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "Poin"
    },
    model: {
      value: _vm.formKomisiPoin.poin.value,
      callback: function callback($$v) {
        _vm.$set(_vm.formKomisiPoin.poin, "value", $$v);
      },
      expression: "formKomisiPoin.poin.value"
    }
  })], 1)], 1)], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }